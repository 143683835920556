body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
   margin:0;
   padding:0;
   height: 100%;
}

#container {
   min-height:100%;
   position: relative;
}
#header {
   padding-bottom: 10px;
}
#body {
  overflow:auto;
  padding-bottom:150px; /* this needs to be bigger than footer height*/
  text-align: center;
}

#footer {
  /*
  position: fixed;
  width: 100%;
  bottom: 0;
  */
}

.stuff {
  width: 400px;
}

.my-alert {
  display:inline-block;
}

.main-banner {
  background: url(/static/media/banner.0dfb92ce.jpg) no-repeat center center;
  background-size: contain;
  min-height: 2vw;
  width: 100%;
}

.main-banner-text {
  padding-top: 150px;
  font-size:5vw;
  text-align: center;
  margin: auto;
  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6)
}

.custom-dropdown {
  width: 237px;
}

.App {
  text-align: center;
}

.App-logo {
  width: 250px;
  height: auto;
}

.App-banner {
  width: 100%;
  height: auto;
}

.SearchContainer {
  margin-top: 60px;
  margin-bottom: 60px;
  padding-top: 60px;
  padding-bottom: 60px;
}

.header-row {
  background-color: #062045;
}

.footer {
  width: 100%;
  background-color: #ffffff;
}

.nt-newsletter {
  color: #ffffff;
  background-color: #062045;
}

.social [class*="fa fa-"] {
    background-color: #333;
    border-radius: 30px;
    color: #fff;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    margin: auto 3px;
    width: 30px;
    font-size: 15px;
    text-align: center;
}

.App-header {
  height: auto;
}

.App-title {
  font-size: 1.0em;
}

.App-intro {
  font-size: large;
}

.sign-up-box {
  padding: 40px;
  margin: 20px;
  border-radius: 30px;
  background: #d7d7d7;

}

.cart-remove {
  cursor: pointer;
}






.page {
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
}






/* simple - enter transition 300ms, exit 150ms */
/*
.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear 150ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 150ms linear;
}
*/








/* advanced - enter transition 800ms, exit 150ms */
.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
/*
.fade-appear p,
.fade-enter p {
  transform: translateY(-5px);
  opacity: 0;
}
*/
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: opacity 150ms linear 150ms, -webkit-transform 300ms ease-out 150ms;
  transition: opacity 150ms linear 150ms, transform 300ms ease-out 150ms;
  transition: opacity 150ms linear 150ms, transform 300ms ease-out 150ms, -webkit-transform 300ms ease-out 150ms;
}
/*
.fade-appear-active p,
.fade-enter.fade-enter-active p {
  transform: translateY(0);
  opacity: 1;
}
.fade-appear-active p:nth-child(2),
.fade-enter.fade-enter-active p:nth-child(2) {
  transition: opacity 300ms linear 300ms, transform 300ms ease-out 300ms;
}
.fade-appear-active p:nth-child(3),
.fade-enter.fade-enter-active p:nth-child(3) {
  transition: opacity 300ms linear 400ms, transform 300ms ease-out 400ms;
}
.fade-appear-active p:nth-child(4),
.fade-enter.fade-enter-active p:nth-child(4) {
  transition: opacity 300ms linear 500ms, transform 300ms ease-out 500ms;
}
*/
.fade-exit {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
.fade-exit.fade-exit-active {
    opacity: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: opacity 150ms linear, -webkit-transform 300ms ease-in;
    transition: opacity 150ms linear, transform 300ms ease-in;
    transition: opacity 150ms linear, transform 300ms ease-in, -webkit-transform 300ms ease-in;
}

.cardContainer {
  @include media-breakpoint-down(xs) {
    &:nth-child(odd) {
      padding-right: 0.5rem;
    }
    &:nth-child(even) {
      padding-left: 0.5rem;
    }
  }
}
.cardContainer a {
  text-decoration: none;
}
.cardContainer a:hover {
  text-decoration: none;
}

.productCard {
  cursor: pointer;
  box-shadow: 0 1px 3px 0;
  transition: box-shadow 0.1s;
  text-decoration: none !important;
}

.price {
  color: black;
}

.name {
  font-size: 18px;
  line-height: 1.3;
  height: 3.6em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  font-size: 14px;
  line-height:1.2em;
  height: 3.6em;
  overflow: hidden;
  text-overflow: ellipsis;
}

