.cardContainer {
  @include media-breakpoint-down(xs) {
    &:nth-child(odd) {
      padding-right: 0.5rem;
    }
    &:nth-child(even) {
      padding-left: 0.5rem;
    }
  }
}
.cardContainer a {
  text-decoration: none;
}
.cardContainer a:hover {
  text-decoration: none;
}

.productCard {
  cursor: pointer;
  box-shadow: 0 1px 3px 0;
  transition: box-shadow 0.1s;
  text-decoration: none !important;
}

.price {
  color: black;
}

.name {
  font-size: 18px;
  line-height: 1.3;
  height: 3.6em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  font-size: 14px;
  line-height:1.2em;
  height: 3.6em;
  overflow: hidden;
  text-overflow: ellipsis;
}
